import React, { Component, useState } from 'react';
import {
   Table,
   TableHead,
   TableHeader,
   TableRow,
   TableBody,
   TableCell,
   TableContainer, 
   TableToolbar,
   TableToolbarContent,
   TableExpandHeader,
   TableExpandRow,
   TableExpandedRow,
   DataTable,
   Button,
   ButtonSet,
   Form,
   TextInput,
   Modal,
   Pagination,
} from 'carbon-components-react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import config from '../../config';

class Photos extends Component {
   constructor(props) {
      super(props);
      this.state = {
         IsLoaded: false,
         Photos: "", 
         ContestSelected: 0,
         Login:"",
      };
   }

   async getPhotoData (contest_ID) {
      await axios.post(config.api_hostname + ':' + config.api_port + '/api/get_contest_photos',
                       { ID: contest_ID }  /* ,
                       { headers: { 'content-type': 'application/x-www-form-urlencoded' } } */ )
         .then((response) => {
            this.setState({ Photos: response.data.list, IsLoaded: true });
         })
   }

   componentDidMount() {
      // Run as soon as page loads
      var loginnanme = this.props.Login;
      var data = this.getPhotoData(this.props.Selection);
//      console.log('page loaded'); 
     }

   render() {
  //    if (this.state.ContestSelected !== this.props.Selection) {
  //       var data = this.getPhotoData(this.props.Selection);
   //      this.setState({ ContestSelected: this.props.Selection });
   //   }

      return (
         <div>
            <div>
               <table>
                  <tr align="center">
                     20 Random photos (refresh for new ones) 
                  </tr>
                  <tr align="center">
                     <img src={this.state.Photos[0]} width="20%" height="20%" ></img>
                     <img src={this.state.Photos[1]} width="20%" height="20%" ></img>
                     <img src={this.state.Photos[2]} width="20%" height="20%" ></img>
                     <img src={this.state.Photos[3]} width="20%" height="20%" ></img>
                     <img src={this.state.Photos[4]} width="20%" height="20%" ></img>
                  </tr>
                  <tr align="center">
                     <img src={this.state.Photos[5]} width="20%" height="20%" ></img>
                     <img src={this.state.Photos[6]} width="20%" height="20%" ></img>
                     <img src={this.state.Photos[7]} width="20%" height="20%" ></img>
                     <img src={this.state.Photos[8]} width="20%" height="20%" ></img>
                     <img src={this.state.Photos[9]} width="20%" height="20%" ></img>
                  </tr>
                  <tr align="center">
                     <img src={this.state.Photos[10]} width="20%" height="20%" ></img>
                     <img src={this.state.Photos[11]} width="20%" height="20%" ></img>
                     <img src={this.state.Photos[12]} width="20%" height="20%" ></img>
                     <img src={this.state.Photos[13]} width="20%" height="20%" ></img>
                     <img src={this.state.Photos[14]} width="20%" height="20%" ></img>
                  </tr>
                  <tr align="center">
                     <img src={this.state.Photos[15]} width="20%" height="20%" ></img>
                     <img src={this.state.Photos[16]} width="20%" height="20%" ></img>
                     <img src={this.state.Photos[17]} width="20%" height="20%" ></img>
                     <img src={this.state.Photos[18]} width="20%" height="20%" ></img>
                     <img src={this.state.Photos[19]} width="20%" height="20%" ></img>
                  </tr>
               </table>   
            </div>
         </div>
      )
   }
}

export default Photos;
