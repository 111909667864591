import React, { Component, Fragment } from 'react';
import {
  Button,
  Tabs,
  Tab,
  Table,
  TableHead,
  TableHeader,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableExpandHeader,
  TableToolbar,
  TableToolbarContent,
  TableExpandRow,
  TableExpandedRow,
  DataTable,
  Modal,
  Dropdown,
} from 'carbon-components-react';
import { Link, redirect } from 'react-router-dom';
import axios from 'axios';
import config from '../../config';
import TeamBoard from '../../components/TeamBoard/TeamBoard';
import Team from '../../components/Team/Team';
import Challenge from '../../components/Challenge/Challenge';
import Activity from '../../components/Activity/Activity';
import Profile from '../../components/Profile/Profile';
import FAQ from '../../components/FAQ/FAQ';
import Motd from "../../components/Motd/Motd";
import Photos from "../../components/Photos"

class LandingPage extends Component {
   constructor(props) {
      super(props);
      this.state = {
         oidc_data: "",
         Authenticated: true,
         Login: "",
 	      Contest: [],
         Selection: 0, // Selected Contest
         Selected: { },  // Selected Contest object
         header:["Name","From","Till","Status"],
         id: "1",
         color1: 10,
         color2: 30,
         color3: 70,
         color4: 100,
      };
      this.handleOnExpandRow = this.handleOnExpandRow.bind(this);
      this.selectContest = this.selectContest.bind(this);
   }

   getUserProfile = async () => {
      let URL = "/get_user_profile"
      await fetch(URL, { method: "get" })
         .then(res => {
            return res.json();
         })
         .then((result) => {
            // alert(JSON.stringify(result));
            if ('Login' in result) {
                 this.setState( { oidc_data: JSON.stringify(result), Authenticated: true, Login: result.Login } );
                 this.props.setAuth (result.Login);
                 this.selectContest(result.LastContest);
            }
            else {
                 this.setState( {oidc_data: JSON.stringify(result), Authenticated: false, Login: ""} );
                 this.props.setAuth ("");
            }
        },
        (error) => {
           this.setState( {Authenticated: false, Login: "" } );
        })
   }

   async componentDidMount() {
      var oidc_userdata = this.getUserProfile();
      oidc_userdata.then( (response) => { 
         this.setState( {oidc_data: response} );
      });

      await axios.get(config.api_hostname + ':' + config.api_port + '/api/get_contest_list', {
         headers: {
//            'Access-Control-Allow-Origin' : '*',
//            'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
//            'Access-Control-Allow-Headers' : 'Origin, X-Requested-With, Content-Type, Accept, Authorization'
         }
      })
         .then((response) => {
            const contest_data = response.data;
            for (var i = 0; i < contest_data.length; i++) {
               contest_data[i].From = contest_data[i].From.replace('T',' ').substring(0,19);
               contest_data[i].Till = contest_data[i].Till.replace('T',' ').substring(0,19);
               contest_data[i].Challenge = ""; // add empty element for column    
               contest_data[i].IsExpanded = false;
            }
            this.setState({ Contest: contest_data});
         });
   }
  
   handleOnExpandRow(rowId) {
      var c = this.state.Contest;
      for (var i = 0; i < c.length; i++) 
         if (c[i].ID === rowId)
            c[i].IsExpanded = ! c[i].IsExpanded;
      this.setState( { Contest: c } );
   };

   selectContest(id) {
      this.setState({ Selection: id  });
      for (var i = 0; i < this.state.Contest.length; i++)
         if (this.state.Contest[i].ID === id) {
            this.setState({ Selected: this.state.Contest[i], Selection: this.state.Contest[i].ID });
            axios.post(config.api_hostname + ':' + config.api_port  + '/api/set_user_contest',
                       { LastContest: this.state.Contest[i].ID } /*,
                       { headers: { 'content-type': 'application/x-www-form-urlencoded' } } */ )
            .then((response) => {
            })
         }
   }

   selectContestEvent (event) {
      const value = event.selectedItem.ID;
      this.setState({ Selection: value });
      for (var i = 0; i < this.state.Contest.length; i++)
         if (this.state.Contest[i].ID === value) {
            this.setState({ Selected: this.state.Contest[i], Selection: this.state.Contest[i].ID });
            axios.post(config.api_hostname + ':' + config.api_port  + '/api/set_user_contest',
                       { LastContest: this.state.Contest[i].ID } /*,
                       { headers: { 'content-type': 'application/x-www-form-urlencoded' } } */ )
            .then((response) => {
            })
         }
   };

   // secured content as logged in user
   render() {
      return (
         <div className="bx--grid bx--grid--full-width landing-page">
            <div className="bx--row landing-page__banner">
               <div className="bx--col-lg-16">
                  <h1 className="landing-page__heading">IBM Team Challenge</h1>
               </div>
               <div className="bx--col-lg-16">
                  <Motd />
               </div>
               <div width="300px" className="contest_selection">
                  <Dropdown
                     id="ContestSelection"
                     titleText="Contest Selection"
                     helperText="                                        "
                     label="Select your Contest"
                     autosize={true}
                     size='large'
                     initialSelectedItem={this.state.Selected}
                     items={this.state.Contest}
                     onChange={(e) => this.selectContestEvent(e)}
                     itemToString={(item) => (item ? item.Name : '')}
                  />
               </div>
            </div>
            <div className="bx--row landing-page__r2">
               <div className="bx--col bx--no-gutter">
                  <Tabs aria-label="Tab navigation">
                  { (this.state.Authenticated === true) && (
                  <Tab label="Photos Posted">
                     <div className="bx--grid bx--grid--no-gutter bx--grid--full-width">
                        <div className="bx--row landing-page__tab-content">
                           <div className="bx--col-lg-16">
                                 {(this.state.Authenticated === true) && (
                                    <Photos {...this.state} getAuth={this.props.getAuth} setAuth={this.props.setAuth}  />
                                 )}
                           </div>
                        </div>
                     </div>
                  </Tab>
                  )}
	          {(this.state.Selection > 0) && (
                     <Tab label="Your Team">
                     <div className="bx--grid bx--grid--no-gutter bx--grid--full-width">
                        <div className="bx--row landing-page__tab-content">
                           <div className="bx--col-lg-16">
                              {(this.state.Authenticated === true) && (
                                  <TeamBoard {...this.state} getAuth={this.props.getAuth} setAuth={this.props.setAuth}  />
                              )}
                           </div>
                        </div>
                     </div>
                     </Tab>
                  )}
	          {(this.state.Selection > 0) && (
                     <Tab label="All Teams">
                     <div className="bx--grid bx--grid--no-gutter bx--grid--full-width">
                        <div className="bx--row landing-page__tab-content">
                           <div className="bx--col-lg-16">
                              {(this.state.Authenticated === true) && (
                                  <Team {...this.state} getAuth={this.props.getAuth} setAuth={this.props.setAuth}  />
                              )}
                           </div>
                        </div>
                     </div>
                     </Tab>
                  )}
                  {(this.state.Selection > 0) && (
                     <Tab label="Your Scores">
                        <div className="bx--grid bx--grid--no-gutter bx--grid--full-width">
                           <div className="bx--row landing-page__tab-content">
                              <div className="bx--col-lg-16">
                                 {(this.state.Authenticated === true) && (
                                    <Challenge {...this.state} getAuth={this.props.getAuth} setAuth={this.props.setAuth}  />
                                 )}
                              </div>
                           </div>
                        </div>
                     </Tab>
                  )}
	          {(this.state.Selection === 0) && (this.state.Authenticated === true) && (
                  <Tab label="Contest List">
                  <div className="bx--grid bx--grid--no-gutter bx--grid--full-width">
                     <div className="bx--row landing-page__tab-content">
                        <div className="bx--col-lg-16">
                           <TableContainer title={"Contest List"} description={"Contest List"}>
                              <TableToolbar aria-label="data table toolbar">
                                 <TableToolbarContent>
                                 </TableToolbarContent>
                              </TableToolbar>
                              <Table size="sm" useZebraStyles={false}>
                                 <TableHead>
                                    <TableRow>
                                       <TableExpandHeader />
                                          {this.state.header.map((header) => (
                                             <TableHeader id={header.key} key={header}>
                                                {header}
                                             </TableHeader>
                                          ))}
                                    </TableRow>
                                 </TableHead>
                                 <TableBody>
                                    {this.state.Contest.map((row) => (
             			       <React.Fragment key={row.id}>
                                       <TableExpandRow isExpanded={row['IsExpanded']} onExpand={() =>
                                          this.handleOnExpandRow(row['ID'])} key={row['ID']}>
                                          <TableCell key='Name'>{row['Name']}</TableCell>
                                          <TableCell key='From'>{row['From']}</TableCell>
                                          <TableCell key='Till'>{row['Till']}</TableCell>
                                          { (row['Enabled'] === 1)  && (
                                             <TableCell key={'Status'}><Link onClick={() =>
                                             this.selectContest(row['ID'])}>Select</Link></TableCell>
                                          ) }
                                          { (row['Enabled'] === 0)  && (
                                             <TableCell key={'Status'}>Closed</TableCell>
                                          ) }
                                       </TableExpandRow>
                                       <TableExpandedRow colSpan={this.state.header.length+1 } className="demo-expanded-td">
                                       <h6>Description</h6>
                                       <div>{row["Description"]}</div>
                                       </TableExpandedRow>
                                       </React.Fragment>
                                    ))}
                                 </TableBody>
                              </Table>
                           </TableContainer>
                        </div>
                     </div>
                  </div>
                  </Tab>
                  )}

	          {(this.state.Selection > 0) && (
                  <Tab label="Your Activities">
                     <div className="bx--grid bx--grid--no-gutter bx--grid--full-width">
                        <div className="bx--row landing-page__tab-content">
                           <div className="bx--col-lg-16">
                                 {(this.state.Authenticated === true) && (
                                    <Activity {...this.state} getAuth={this.props.getAuth} setAuth={this.props.setAuth}  />
                                 )}
                           </div>
                        </div>
                     </div>
                  </Tab>
                  )}
	          { (this.state.Authenticated === true) && (
                  <Tab label="Your Profile">
                     <div className="bx--grid bx--grid--no-gutter bx--grid--full-width">
                        <div className="bx--row landing-page__tab-content">
                           <div className="bx--col-lg-16">
                                 {(this.state.Authenticated === true) && (
                                    <Profile {...this.state} getAuth={this.props.getAuth} setAuth={this.props.setAuth}  />
                                 )}
                           </div>
                        </div>
                     </div>
                  </Tab>
                  )}
                  <Tab label="Help">
                     <div className="bx--grid bx--grid--no-gutter bx--grid--full-width">
                        <div className="bx--row landing-page__tab-content">
                           <div className="bx--col-lg-16">
                                 {(this.state.Authenticated === true) && (
                                    <FAQ {...this.state} />
                                 )}
                           </div>
                        </div>
                     </div>
                  </Tab>
               </Tabs>
            </div>
         </div>

         {(this.state.Authenticated === false) && (
         <Modal
            open
            modalHeading="Welcome to the IBM Team Challenge !!!"
            modalLabel="Login"
            primaryButtonText="Proceed to login / registration"
            onRequestSubmit={() => window.open("/login", "_self")}>
            <h2>
            <img src="icon/sport-logo.png" width="150" height="150"></img>
            <p style={{ marginBottom: '2rem' }}>To proceed to the portal you must login first</p>
            <p style={{ marginBottom: '2rem' }}>If you are a new user click the button below and then register as a new user.</p>
            <p style={{ marginBottom: '2rem' }}>Logging in does or registration as a new user NOT mean you join automatically the competition. Afterwards, you join the competition by creating your team or by joining an existing team.</p>
            <p style={{ marginBottom: '2rem' }}>Please make sure you always use https:// to access the portal, otherwise you will not see any data. For the best experience please use Google Chrome..</p>
            </h2>
         </Modal>
         )}

      </div>
      )
   }
}

export default LandingPage;
